import { LocaleMessages } from "vue-i18n";

const messages: LocaleMessages = {
  "en-US": {
    UI: {
      general: {
        Dashboard: "Dashboard",
        CaseOverview: "Case Overview",
        CaseCreate: "New Case",
        Settings: "Settings",
        Logout: "Logout",
        filter: "Filter",
        save: "Save",
        close: "Back to Overview",
        edit: "Edit",
        cancel: "Cancel",
        confirm: "Okay",
        dateInvalid: "Unknown",
        admin: "Administration",
        loading: "Loading",
        toady: "Today",
        clearDate: "Clear Date",
      },
      nav: {
        allLocations: "All Locations",
        logout: "@:UI.general.Logout",
      },
      title: {
        prefix: "USP -",
        Dashboard: "@:UI.title.prefix @:UI.general.Dashboard",
        CaseOverview: "@:UI.title.prefix @:UI.general.CaseOverview",
        CaseCreate: "@:UI.title.prefix @:UI.general.CaseCreate",
        Detail: "@:UI.title.prefix Detail",
        Edit: "@:UI.title.prefix @:UI.general.edit",
        Admin: "@:UI.title.prefix @:UI.general.admin",
        AdminUserList:
          "@:UI.title.prefix @:UI.general.admin - @:UI.admin.userList.title",
      },
      admin: {
        headline: "@:UI.general.admin",
        userList: {
          title: "User List",
          status: "Status",
          fullName: "User Name",
          partner: "@:UI.overview.customer",
          location: "@:UI.form.location",
          email: "@:UI.form.email",
          syncKeycloak: "Update Keycloak",
        },
        jl: {
          title: "J-Lawyer @:UI.general.admin",
          addInsurances: "Add Insurances",
          addLocations: "Add Locations",
        },
      },
      dashboard: {
        newCases: "New Cases",
        closedCases: "Closed Cases",
        casesTotal: "Total",
        casesOpen: "Drafts",
        casesInProgress: "In Progress",
        casesClosed: "Closed",
        withoutAppointment: "Without Repair Appointment",
        canceled: "Canceled",
        repairDecisions: "Settlement Types",
        advisories: "Expert Opinions",
        regulationRatios: "Regulation Ratios",
        regulationSums: "Regulation Amounts",
        noClosedCases: "No Closed Cases Found",
        noCasesFound: "No Cases Found",
        tooltips: {
          newCases:
            "All new cases from the last 13 months per location. Filterable via the legend below the chart.",
          closedCases:
            "All cases closed within the last 6 months per location. Filterable via the legend below the chart.",
          repairDecisions:
            "Based on the selection in the accident damage capture mask.",
          advisories:
            "Proportion of how many cases were settled with/without an expert. Based on the data in the accident damage capture mask.",
          regulationRatios:
            "Real-time data of all closed cases from the internal systems of the case handler.",
          regulationSums: "@:UI.dashboard.tooltips.regulationRatios",
          withoutAppointment:
            "Specifically settled cases without a repair appointment",
        },
      },
      overview: {
        title: "Overview",
        PdfExport: "PDF Export",
        MarkAsRead: "Mark as Read",
        customer: "Partner",
        location: "@:UI.form.location",
        created: "@:UI.form.creation_date",
        closed: "@:UI.enum.status.CLOSED",
        released: "Released",
        details: "Details",
        edit: "Edit",
        updated: "@:UI.form.last_modified_date",
        filteredCases: "Showing {filtered} of {all} cases",
        noCasesToShow: "No Cases Available",
        repairWanted: "@:UI.form.repair_wanted",
        page: "Page",
        currentPage: "Current Page",
        nextPage: "Next Page",
        previousPage: "Previous Page",
        perPage: "Per Page",
        sortingFor: "Sort by:",
        na: "Not selected",
      },
      detail: {
        PowerOfAttorney: "Power of Attorney",
        attachments: "Attachments",
        uploadFiles: "Upload: Drop files here or click (Max. {size} MB/file)",
        upload: "Upload",
        fileToLarge: "File is too large and cannot be processed",
        deleteAll: "Delete All",
        release: "Release",
        delete: "Delete",
        close: "Complete",
        reopen: "Reopen Case",
        addPOA: "Create Power of Attorney",
        comments: "Comments",
        history: "History",
        evaluationDetails: "Regulation Details",
        numberPrefix: "No. {number}",
        repairInvoice: "Repair Invoice",
        rentalInvoice: "Rental Car Invoice",
        issueDate: "Submission Date",
        substitutionNoticeDate: "Representation Notice",
        advanceRequestDate: "Advance Request",
        issued: "Submitted",
        notIssuedYet: "No Submission Yet",
        received: "Regulated",
        advanceReceived: "Advance Received",
        sendToJL: "Transfer Case",
        notifyColleague: "Notify Colleague:",
        filterColleagues: "Search Colleague...",
        notifiedColleagues: "Colleagues Notified:",
        noColleagues: "No Colleagues Found",
        cancelCase: "Cancel Case",
        releaseInfo:
          'The case cannot be released at the moment! For a case to be released, a document under "Attachments" must be tagged as "Power of Attorney signed"!',
      },
      create: {
        title: "New Case",
      },
      form: {
        general: "General Information",
        id: "Case ID",
        creation_date: "Recorded",
        last_modified_date: "Last Modified",
        created_by: "by",
        last_modified_by: "@:UI.form.created_by",
        released: "Released",
        injuredParty: "Customer",
        reporter: "Claim Reporter",
        kind: "Type of Injured Party",
        title: "Title",
        street1: "Street",
        street2: "Address Additional",
        zip: "Postal Code",
        city: "City",
        country: "Country",
        phone: "Phone",
        email: "Email",
        injured_iban: "IBAN",
        legal_insurance_available: "Legal expenses insurance available?",
        legal_insurance_id: "Legal expenses insurance",
        legal_insurance: "@:UI.form.other",
        company: "Company Name",
        first_name: "First Name",
        last_name: "Last Name",
        vehicle: "Accident Vehicle",
        vehicle_insurance: "Comprehensive Insurance",
        opponent_insurance: "Opponent's Insurance",
        vehicle_license_plate: "License Plate",
        licensePlateCountry: "License Plate (Country)",
        opponent_license_plate_country: "@:UI.form.licensePlateCountry",
        roadworthy: "Roadworthy",
        vehicle_ownership: "Vehicle is...",
        vehicle_bank: "Leasing/Financing Provider",
        accident: "Accident Data",
        date: "Date",
        accident_date: "@:UI.form.date",
        accident_country: "Country (if not DE)",
        accident_place: "Accident Location",
        accident_national: "Accident Abroad?",
        police_alerted: "Recorded by Police?",
        police_inspection: "Station",
        police_reference: "Police File Number",
        accident_course: "Course of the Accident",
        file_sign: "Case Handler File Number",
        file_sign_lawyer: "File Number (internal)",
        rental_wanted: "Rental Car/Loss of Use?",
        repair_wanted: "Settlement Type",
        repair_date: "Repair Date",
        witness: "Witness",
        witness_available: "Witness Available?",
        additional_infos: "Additional Information",
        opponent: "Opponent",
        opponent_claim_number: "Claim Number",
        insurance_policy: "Insurance Policy",
        opponent_insurance_policy: "@:UI.form.insurance_policy",
        driver_is_owner: "Driver is Owner?",
        owner: "Opponent's Data",
        driver: "Driver",
        other: "Other",
        injured_vat_deduction: "Entitled to Deduct VAT?",
        expert_office: "Expert Office",
        expert_employee: "Expert Office Employee",
        expert_reference: "Expert Office Reference",
        advisory: "Expert Opinion",
        physical_injury: "Personal Injury",
        insurance_contacted: "Insurance Contact?",
        opponent_license_plate: "License Plate",
        location: "Location",
        version: "Version",
        damage_type: "Type of Damage",
        vehicle_id: "VIN",
        customer_reference: "Customer Number",
        closed_date: "Closing Date",
        caseHandler: "Case Handler",
        car_manufacturer: "Manufacturer",
        car_model: "Model",
        self_involvement_partial: "Partial Comprehensive Deductible",
        self_involvement_full: "Comprehensive Deductible",
        issue_date: "@:UI.detail.issueDate",
        repair_invoice_no: "@:UI.detail.repairInvoice",
        repair_costs_issued: "Repair Costs Submitted",
        repair_costs_received: "Repair Costs Regulated",
        rental_invoice_no: "@:UI.detail.rentalInvoice",
        rental_costs_issued: "Rental Car Costs Submitted",
        rental_costs_received: "Rental Car Costs Regulated",
        substitution_notice_date: "@:UI.detail.substitutionNoticeDate",
        advance_request_date: "@:UI.detail.advanceReceived",
        depreciation_issued: "Depreciation Submitted",
        depreciation_received: "Depreciation Regulated",
        expert_fees_issued: "Expert Fees Submitted",
        expert_fees_received: "Expert Fees Regulated",
        placeholder: {
          additional_infos:
            "When using a tablet, you can conveniently use the speech recognition of your device here.",
        },
      },

      tooltip: {
        IBAN: "Required to make damage payments to the customer. Account access is not possible.",
        email:
          "Providing an email address allows for quick and uncomplicated correspondence with the customer.",
        injuredVatDeduction:
          "If entitled to deduct input tax, damage claims are made net. The incurred VAT amounts must be settled directly with the service providers by the customer.",
        roadworthy:
          "The condition according to the expert report/preliminary information influences the assessment of how long there is a claim for a rental car/loss of use.",
        ownership:
          "If a vehicle is leased/financed, the respective leasing/financing provider must be informed/contacted in the process of damage settlement.",
        national:
          "If an accident occurs abroad, the respective national law applies. Damage claims differ accordingly, with a delayed processing to be expected.",
        policeAlerted:
          "In case of unclear damage events, the police accident report, if available, may provide clarity under certain circumstances.",
        accidentCourse:
          "A concise original description by the customer is recommended.",
        licensePlateCountry:
          "In the case of foreign parties involved in an accident, the settlement of the accident damage may be delayed.",
        opponentLicensePlate:
          "The license plate can be used to identify the opposing insurance, if not known.",
        rentalWanted:
          "The customer has the option between replacement mobility or a financial compensation. The duration of the claim varies depending on the type of settlement and specific case.",
        repairWanted:
          "Concrete = The vehicle will be repaired. Fictional = Compensation is determined net according to the expert report.",
        repairDate:
          "If known, stating this avoids premature inquiries by your case handler about the current status.",
        physicalInjury:
          "Personal injuries are handled separately from vehicle damage for data protection reasons. Who is affected, special circumstances etc. can be maintained in the additional information.",
        legalInsuranceAvailable:
          "The query is purely informational at the time of damage recording. Utilization of any legal protection is only made if necessary and in the course of the case after coordination with the customer.",
        legalInsuranceId:
          "If insurance is unknown select “Other” (end of the list)",
        notifyColleague:
          "Only registered users can be notified. Entered email addresses will not be considered. Your case handler will be automatically informed as soon as a message is saved. After saving a message, the distribution list is displayed below the comment.",
        sendEmail: "E-Mail to customer",
        sendEmailDisabled: "No email address available",
      },
      dialog: {
        closeCase: {
          title: "Close case",
          message: "Do you really want to close the case?",
          confirmText: "@:UI.dialog.closeCase.title",
          cancelText: "@:UI.general.cancel",
        },
        deleteCase: {
          title: "Delete case",
          message: "Do you really want to delete the case?",
          confirmText: "@:UI.dialog.deleteCase.title",
          cancelText: "@:UI.general.cancel",
        },
        releaseCaseThirdParty: {
          title: "Release case",
          message:
            "If you confirm the release, the process will be transmitted to your case handler for processing. Do you want to do this?",
          confirmText: "@:UI.dialog.releaseCaseThirdParty.title",
          cancelText: "@:UI.general.cancel",
        },
        releaseCaseOwnFault: {
          title: "@:UI.dialog.releaseCaseThirdParty.title",
          message:
            "Please note that it makes sense for your customer to have reported/notified the damage to his comprehensive insurance independently.<br>" +
            "Advise him of this and also ask about any workshop binding",
          confirmText: "@:UI.dialog.releaseCaseThirdParty.title",
          cancelText: "@:UI.general.cancel",
        },
        locationError: {
          title: "No location set",
          message:
            "A location must be selected to save. This can be changed later if required.",
          confirmText: "@:UI.general.confirm",
        },
        cancelCaseNoInfos: {
          title: "Cancel case",
          message:
            "To cancel a process, entering a reason in the additional information field is necessary.",
          confirmText: "@:UI.general.confirm",
        },
        cancelCase: {
          title: "@:UI.dialog.cancelCaseNoInfos.title",
          message: "Do you really want to cancel the case?",
          confirmText: "@:UI.dialog.cancelCase.title",
          cancelText: "@:UI.general.cancel",
        },
        addFilter: {
          title: "Add filter",
          cancelText: "@:UI.dialog.closeCase.cancelText",
          confirmText: "@:UI.dialog.addFilter.title",
        },
        addPOAForm: {
          title: "Incomplete data",
          injuredNameMissing: "No name available for customer",
          accidentDateMissing: "No accident date specified",
          accidentPlaceMissing: "No accident location specified",
          questionText:
            "<br>The power of attorney can only be created once the above information has been stored. Once the data fields are filled, the power of attorney can be created and digitally signed.",
          confirmText: "@:UI.general.confirm",
        },
        deleteAttachment: {
          title: "Delete attachment",
          message:
            "Do you really want to delete the attachment?<br><br>Restoration will no longer be possible!",
          confirmText: "@:UI.dialog.deleteAttachment.title",
          cancelText: "Cancel",
        },
        sendPoaToCustomer: {
          title: "Send Power of attorney to customer",
          message: "Do you want to send the power of attorney to the customer?",
          confirmText: "@:UI.enum.general.YES",
          cancelText: "@:UI.enum.general.NO",
        },
        sendDocumentToCustomer: {
          title: "Send document to customer",
          message: "Do you want to send the document to the customer?",
          confirmText: "@:UI.enum.general.YES",
          cancelText: "@:UI.enum.general.NO",
        },
      },
      error: {
        general: {
          title: "Error",
          notAllowed: "Access to this page was denied",
          locationLoading: "Customer list could not be loaded",
          missingLocation: "Please select a location",
        },
        server_na: {
          message: "Server was not reached.<br><br>You will be logged out.",
          confim: "Log out",
        },
        overview: {
          loadingCases: "Error loading the overview",
        },
        cases: {
          loading: "Error loading the case",
          create: "Error saving the new case",
          update: {
            default: "Error saving the case",
            case_modified:
              "Error saving the case. The case was modified by another user in the meantime. Please reload the page.",
          },
          notAllowed: "Access to this case was denied",
        },
        comments: {
          loading: "Error loading the comments",
          create: "Error saving the comment",
        },
        history: {
          loading: "Error loading the case history",
        },
        attachment: {
          upload: "Error uploading the file",
          download: "Error downloading the file",
          delete: "Error deleting the file",
          load: "Error loading the attachments",
          createPoa: "Error creating the power of attorney",
          sendToJL: "Error transferring the case to J-Lawyer",
          updateTag: "Error setting the tag",
        },
        status: {
          update: "Error updating the status",
        },
        data: {
          insurance: "Error loading the insurance list",
          legalInsurance: "Error loading the traffic legal insurance list",
          location: "Error loading the location list",
          partner: "Error loading the partner list",
          evaluation: "Error loading the evaluation",
          colleagues: "Error loading the list of all colleagues",
          caseHandler: "Error loading the list of all case handlers",
          appraisersUsers: "Error loading the list of appraisers users",
          appraisers: "Error loading the list of appraisers",
        },
        admin: {
          loadCaseHandler: "Error loading the case handlers",
          addInsuranceLoading: "Error adding missing insurances",
        },
        iban: {
          0: "No IBAN provided", // NoIBANProvided
          1: "No country for the IBAN provided", // NoIBANCountry
          2: "Wrong length of the IBAN", // WrongBBANLength
          3: "Wrong format of the IBAN", // WrongBBANFormat
          4: "Checksum is not a number", // ChecksumNotNumber
          5: "Wrong IBAN checksum", // WrongIBANChecksum
          6: "Wrong checksum of the IBAN", // WrongAccountBankBranchChecksum
          7: "QR-IBAN not allowed", // QRIBANNotAllowed
        },
      },
      message: {
        noResultsFor: "No results for",
        selectCountry: "Select Country",
        searchInsurance: "Search insurance",
        notAvailable: "-",
        success: "Successful",
        createPoa: "The power of attorney is being created",
        addedPoa: "The power of attorney was successfully created",
        sendToJL: "The case is being handed over to J-Lawyer",
        sendedToJL: "The case was successfully handed over to J-Lawyer",
        attachmentUpdated:
          "File {fileName} successfully saved. @:UI.message.caseHandlerNotification",
        caseHandlerNotification:
          "Your case handler has been automatically informed.",
        caseHandlerNotNotified:
          "Attention: your case handler is not informed for processes in draft status.",
        attachmentAdded:
          "File {fileName} successfully added. @:UI.message.caseHandlerNotification",
        attachmentDeleted: "File {fileName} successfully removed.",
        commentAdded: "Comment successfully saved.",
        commentAddedReleased:
          " @:UI.message.commentAdded @:UI.message.caseHandlerNotification",
        commentAddedUnreleased:
          " @:UI.message.commentAdded @:UI.message.caseHandlerNotNotified",
        documentSent: "Document successfully sent to the customer.",
      },
      history: {
        from: ", from {name}",
        field: "Field",
        oldValue: "Old value",
        newValue: "New value",
        empty: "(undefined)",
        viewMore: "Show more",
        viewLess: "Show less",
        loading: "History is loading",
        attachment_added_tag: "'{tag}' to file {file_name}",
        attachment_modified_tag:
          "'{new_tag}' from '{old_tag}' at file {file_name}",
        attachment_deleted_tag: "'{tag}' from {file_name}",
      },
      enum: {
        general: {
          SELECT: "Please select",
          YES: "Yes",
          NO: "No",
          UNKNOWN: "Unknown/Unclear",
          OTHER: "Other",
        },
        status: {
          NONE: "@:UI.enum.general.SELECT",
          WORK_IN_PROGRESS: "Draft",
          RELEASED: "In process",
          CLOSED: "Closed",
          CANCELLED: "Cancelled",
        },
        tag: {
          LIABILITY_CONFIRMATION_ISSUED: "Liability confirmation issued",
          RENTAL_CAR_PRICE_INFORMATION_RECEIVED:
            "Rental car: Price pre-information",
          ADVANCE_RECEIVED: "Advance received",
        },
        reporter: {
          NONE: "@:UI.enum.general.SELECT",
          DRIVER: "Driver",
          OWNER: "Owner",
        },
        vehicle_ownership: {
          NONE: "@:UI.enum.general.SELECT",
          OWN_PROPERTY: "...in property",
          FINANCED: "...financed",
          LEASED: "...leased",
          UNKNOWN: "Unknown",
        },
        kind: {
          NONE: "@:UI.enum.general.SELECT",
          PRIVATE: "Private",
          COMPANY: "Company",
        },
        decision: {
          NONE: "@:UI.enum.general.SELECT",
          YES: "@:UI.enum.general.YES",
          NO: "@:UI.enum.general.NO",
          UNKNOWN: "@:UI.enum.general.UNKNOWN",
        },
        advisory: {
          NONE: "@:UI.enum.general.SELECT",
          YES: "@:UI.enum.general.YES",
          NO: "@:UI.enum.general.NO",
          SHORT_ADVISORY: "Short report/Damage calculation",
          COST_ESTIMATE: "Cost estimate dealership",
        },
        title: {
          NONE: "@:UI.enum.general.SELECT",
          MR: "Mr",
          MRS: "Mrs",
          COMPANY: "Company",
          OTHER: "Diverse/Other",
        },
        rental_wanted: {
          NONE: "@:UI.enum.general.SELECT",
          RENTAL: "Rental car",
          OUTAGE_COSTS: "Loss of use",
          UNKNOWN: "@:UI.enum.general.UNKNOWN",
        },
        repair_wanted: {
          NONE: "@:UI.enum.general.SELECT",
          CONCRETE: "Concrete",
          FICTIONAL: "Fictional",
          TOTAL_DAMAGE: "Total loss",
          UNKNOWN: "@:UI.enum.general.UNKNOWN",
        },
        roadworthy: {
          NONE: "@:UI.enum.general.SELECT",
          FULL: "Roadworthy and traffic-safe",
          NOT_ALLOWED: "Roadworthy and not traffic-safe",
          NO: "Not roadworthy and not traffic-safe",
          UNKNOWN: "@:UI.enum.general.UNKNOWN",
        },
        damage_type: {
          NONE: "@:UI.enum.general.SELECT",
          THIRD_PARTY_LIABILITY: "Third-party liability",
          OWN_FAULT: "Partial/Comprehensive insurance",
        },
        filterField: {
          NONE: "@:UI.enum.general.SELECT",
          STATUS: "Status",
          CREATED: "Creation date",
          UPDATED: "Last modified",
          CLOSED: "Closing date",
          LOCATION: "Location",
          CUSTOMER_NAME: "Customer name",
          LICENSE_PLATE: "License plate",
          FILE_SIGN: "@:UI.form.file_sign",
          REPAIR_WANTED: "@:UI.form.repair_wanted",
          CASE_HANDLER: "@:UI.form.caseHandler",
          DAMAGE_TYPE: "@:UI.form.damage_type",
          EXPERT_REFERENCE: "Expert reference",
        },
        caseFilters: {
          REPAIR_DATE_MISSING: "Missing repair date",
        },
        sortField: {
          LAST_MODIFICATION_DATE: "Last Modified",
          CREATION_DATE: "Creation Date",
          RELEASE_DATE: "Release Datum",
        },
        sortDirection: {
          ASC: "Ascending",
          DESC: "Descending",
        },
        diffType: {
          OTHER: "",
          CASE: "Case",
          INJURED_PARTY_ADDRESS: "@:UI.form.injuredParty",
          OPPONENT_OWNER_ADDRESS: "@:UI.form.owner",
          OPPONENT_DRIVER_ADDRESS: "@:UI.form.driver",
          WITNESS_ADDRESS: "@:UI.form.witness",
          ATTACHMENT: "Attachment",
          ATTACHMENT_TAG: "Attachment Tag",
          COMMENT: "Comment",
          EVENT: "Event",
          EVALUATION_VALUES: "@:UI.detail.evaluationDetails",
        },
        diffReason: {
          OTHER: "",
          ADDED: "added",
          MODIFIED: "modified",
          DELETED: "deleted",
        },
        diffEvent: {
          TAG: "",
          STATUS: "Case status changed to",
          MAIL_TO_CUSTOMER: "Document sent to customer",
        },
        user_active: {
          ACTIVE: "Active",
          INACTIVE: "Inactive",
          DISABLED: "Disabled",
        },
        attachment_tag: {
          NONE: "None",
          POA: "Power of attorney blank",
          POA_SIGNED: "Power of attorney signed",
          INVOICE_REPAIR: "Repair invoice",
          ADVISORY: "Expert report",
          INVOICE_RENTAL: "Rental invoice",
          INVOICE_ADVISORY: "Expert report invoice",
          PICTURE: "Picture",
          LETTER_INSURANCE: "Insurance letter",
          LETTER_POLICE: "Police",
          OTHER: "Other",
          QUANTIFICATION: "Quantification",
          TRANSPORTATION_DOCUMENTS: "Transportation documents",
          EXPERT_ASSISTANCE_COSTS: "Expert assistance costs",
          EXPERT_OPINION_ORDER: "Expert opinion order",
        },
        regulation_types: {
          REPAIR_COSTS: "Repair costs",
          RENTAL_CAR_COSTS: "Rental car costs",
          DEPRECIATION: "Depreciation",
          EXPERT_FEES: "Expert fees",
        },
        country: {
          NONE: "@:UI.enum.general.SELECT",
          UA: "Ukraine",
          FR: "France",
          ES: "Spain",
          SE: "Sweden",
          DE: "Germany",
          FI: "Finland",
          NO: "Norway",
          PL: "Poland",
          IT: "Italy",
          GB: "Great Britain",
          RO: "Romania",
          BY: "Belarus",
          GR: "Greece",
          BG: "Bulgaria",
          IS: "Iceland",
          PT: "Portugal",
          CZ: "Czech Republic",
          DK: "Denmark",
          HU: "Hungary",
          RS: "Serbia",
          AT: "Austria",
          IE: "Ireland",
          LT: "Lithuania",
          LV: "Latvia",
          HR: "Croatia",
          BA: "Bosnia and Herzegovina",
          SK: "Slovakia",
          EE: "Estonia",
          NL: "Netherlands",
          CH: "Switzerland",
          MD: "Moldova",
          BE: "Belgium",
          AL: "Albania",
          MK: "Macedonia",
          SI: "Slovenia",
          ME: "Montenegro",
          CY: "Cyprus",
          LU: "Luxembourg",
          FO: "Faroe Islands",
          AD: "Andorra",
          MT: "Malta",
          LI: "Liechtenstein",
          GG: "Guernsey",
          SM: "San Marino",
          GI: "Gibraltar",
          MC: "Monaco",
          VA: "Vatican City",
        },
      },
    },
  },
  "de-DE": {
    UI: {
      general: {
        Dashboard: "Dashboard",
        CaseOverview: "Übersicht",
        CaseCreate: "Neuer Fall",
        Settings: "Einstellungen",
        Logout: "Logout",
        filter: "Filter",
        save: "Speichern",
        close: "Zurück zu Übersicht",
        edit: "Ändern",
        cancel: "Abbrechen",
        confirm: "Okay",
        dateInvalid: "Unbekannt",
        admin: "Administration",
        loading: "Wird geladen",
        toady: "Heute",
        clearDate: "Datum löschen",
      },
      nav: {
        allLocations: "Alle Standorte",
        logout: "@:UI.general.Logout",
      },
      title: {
        prefix: "Unfallschadenplattform -",
        Dashboard: "@:UI.title.prefix @:UI.general.Dashboard",
        CaseOverview: "@:UI.title.prefix @:UI.general.CaseOverview",
        CaseCreate: "@:UI.title.prefix @:UI.general.CaseCreate",
        Detail: "@:UI.title.prefix Ansicht",
        Edit: "@:UI.title.prefix @:UI.general.edit",
        Admin: "@:UI.title.prefix @:UI.general.admin",
        AdminUserList:
          "@:UI.title.prefix @:UI.general.admin - @:UI.admin.userList.title",
      },
      admin: {
        headline: "@:UI.general.admin",
        userList: {
          title: "Benutzerliste",
          status: "Status",
          fullName: "Name Benutzer_in",
          partner: "@:UI.overview.customer",
          location: "@:UI.form.location",
          email: "@:UI.form.email",
          syncKeycloak: "Keycloak aktualisieren",
        },
        jl: {
          title: "J-Lawyer @:UI.general.admin",
          addInsurances: "Versicherungen hinzufügen",
          addLocations: "Standorte hinzufügen",
        },
      },
      dashboard: {
        newCases: "Neue Fälle",
        closedCases: "Abgeschlossene Fälle",
        casesTotal: "Gesamt",
        casesOpen: "Entwürfe",
        casesInProgress: "In Bearbeitung",
        casesClosed: "Abgeschlossen",
        withoutAppointment: "Ohne Reparaturtermin",
        canceled: "Storniert",
        repairDecisions: "Abwicklungsarten",
        advisories: "Gutachten",
        regulationRatios: "Regulierungsquoten",
        regulationSums: "Regulierungssummen",
        noClosedCases: "Keine abgeschlossenen Fälle gefunden",
        noCasesFound: "Keine Fälle gefunden",
        tooltips: {
          newCases:
            "Alle neuen Fälle der letzten 13 Monate pro Standort. Filterbar über die Legende unterhalb der Grafik.",
          closedCases:
            "Alle innerhalb der letzten 6 Monate abgeschlossenen Fälle pro Standort. Filterbar über die Legende unterhalb der Grafik.",
          repairDecisions:
            "Basierend auf der Auswahl in der Unfallschadenerfassungsmaske.",
          advisories:
            "Anteil, wie viele Vorgänge mit/ohne Sachverständigen abgewickelt wurden. Basierend auf den Daten in der Unfallschadenerfassungsmaske.",
          regulationRatios:
            "Echtzeit-Daten aller abgeschlossener Fälle aus den internen Systemen des Fallabwicklers.",
          regulationSums: "@:UI.dashboard.tooltips.regulationRatios",
          withoutAppointment: "Konkret abgewickelte Fälle ohne Reparaturtermin",
        },
      },
      overview: {
        title: "Übersicht",
        PdfExport: "PDF Export",
        MarkAsRead: "Als gelesen markieren",
        customer: "Partner",
        location: "@:UI.form.location",
        created: "@:UI.form.creation_date",
        closed: "@:UI.enum.status.CLOSED",
        released: "Freigegeben",
        details: "Details",
        edit: "Ändern",
        updated: "@:UI.form.last_modified_date",
        filteredCases: "Zeige {filtered} von {all} Vorgängen",
        noCasesToShow: "Keine Fälle vorhanden",
        repairWanted: "@:UI.form.repair_wanted",
        page: "Seite",
        currentPage: "Aktuelle Seite",
        nextPage: "Nächste Seite",
        previousPage: "Vorherige Seite",
        perPage: "Pro Seite",
        sortingFor: "Sortieren nach:",
        na: "Nicht ausgewählt",
      },
      detail: {
        PowerOfAttorney: "Vollmacht",
        attachments: "Anhänge",
        uploadFiles:
          "Upload: Dateien ablegen oder hier klicken (Max. {size} MB/Datei)",
        upload: "Hochladen",
        fileToLarge: "Datei zu ist groß und kann nicht verarbeitet werden",
        deleteAll: "Alle löschen",
        release: "Freigeben",
        delete: "Löschen",
        close: "Abschließen",
        reopen: "Fall wiedereröffnen",
        addPOA: "Vollmacht erstellen",
        comments: "Kommentare",
        history: "Historie",
        evaluationDetails: "Regulierungsdetails",
        numberPrefix: "Nr. {number}",
        repairInvoice: "Reparaturrechnung",
        rentalInvoice: "Mietwagenrechnung",
        issueDate: "Einreichungsdatum",
        substitutionNoticeDate: "Vertretungsanzeige",
        advanceRequestDate: "Vorschussanforderung",
        issued: "Einreichung",
        notIssuedYet: "Bislang keine Einreichung",
        received: "Reguliert",
        advanceReceived: "Vorschussanforderung",
        sendToJL: "Fall übertragen",
        notifyColleague: "Kolleg_in informieren:",
        filterColleagues: "Kolleg_in suchen...",
        notifiedColleagues: "Kolleg_innen informiert:",
        noColleagues: "Keine Kolleg_innen gefunden",
        cancelCase: "Fall stornieren",
        releaseInfo:
          'Der Vorgang kann derzeit nicht freigegeben werden! Damit ein Fall freigegeben werden kann, muss ein Dokument unter "Anhänge" mit dem Etikett "Vollmacht unterschrieben" gekennzeichnet sein!',
      },
      create: {
        title: "Neuer Fall",
      },
      form: {
        general: "Allgemeine Informationen",
        id: "Fall-ID",
        creation_date: "Erfasst",
        last_modified_date: "Zuletzt geändert",
        created_by: "von",
        last_modified_by: "@:UI.form.created_by",
        released: "Freigegeben",
        injuredParty: "Kund_in",
        reporter: "Schadenmelder_in",
        kind: "Art Geschädigte Partei",
        title: "Anrede",
        street1: "Straße",
        street2: "Adresszusatz",
        zip: "PLZ",
        city: "Ort",
        country: "Land",
        phone: "Telefon",
        email: "Mail",
        injured_iban: "IBAN",
        legal_insurance_available:
          "Verkehrsrechtsschutzversicherung vorhanden?",
        legal_insurance_id: "Verkehrsrechtsschutzversicherung",
        legal_insurance: "@:UI.form.other",
        company: "Name Firma",
        first_name: "Vorname",
        last_name: "Nachname",
        vehicle: "Unfallfahrzeug",
        vehicle_insurance: "Kaskoversicherung",
        opponent_insurance: "Versicherung Gegner_in",
        vehicle_license_plate: "Kennzeichen",
        licensePlateCountry: "Kennzeichen (Land)",
        opponent_license_plate_country: "@:UI.form.licensePlateCountry",
        roadworthy: "Fahrbereit/Verkehrssicher",
        vehicle_ownership: "Fahrzeug ist...",
        vehicle_bank: "Leasing-/Finanzierungsgeber",
        accident: "Unfalldaten",
        date: "Datum",
        accident_date: "@:UI.form.date",
        accident_country: "Land (wenn nicht D)",
        accident_place: "Unfallort",
        accident_national: "Auslandschaden?",
        police_alerted: "Polizeilich aufgenommen?",
        police_inspection: "Dienststelle",
        police_reference: "Aktenzeichen Polizei",
        accident_course: "Schadenhergang",
        file_sign: "Aktenzeichen Fallabwickler",
        file_sign_lawyer: "Aktenzeichen (intern)",
        rental_wanted: "Mietwagen/Nutzungsausfall?",
        repair_wanted: "Abwicklungsart",
        repair_date: "Reparaturdatum",
        witness: "Zeug_in",
        witness_available: "Zeug_in vorhanden?",
        additional_infos: "Zusatzinformationen",
        opponent: "Gegner_in",
        opponent_claim_number: "Schadennummer",
        insurance_policy: "Versicherungsschein",
        opponent_insurance_policy: "@:UI.form.insurance_policy",
        driver_is_owner: "Fahrer_in ist Besitzer_in?",
        owner: "Daten Gegner_in",
        driver: "Fahrer_in",
        other: "Sonstiges",
        injured_vat_deduction: "Vorsteuerabzugsberechtigt?",
        expert_office: "Sachverständigenbüro",
        expert_employee: "Mitarbeiter_in Sachverständigenbüro",
        expert_reference: "Referenz Sachverständigenbüro",
        advisory: "Gutachten",
        physical_injury: "Personenschaden",
        insurance_contacted: "Versicherungskontakt?",
        opponent_license_plate: "Kennzeichen",
        location: "Standort",
        version: "Version",
        damage_type: "Schaden-Art",
        vehicle_id: "VIN",
        customer_reference: "Kund_in Nummer",
        closed_date: "Abschlussdatum",
        caseHandler: "Fallabwickler",
        car_manufacturer: "Hersteller",
        car_model: "Modell",
        self_involvement_partial: "Selbstbeteiligung Teilkasko",
        self_involvement_full: "Selbstbeteiligung Vollkasko",
        issue_date: "@:UI.detail.issueDate",
        repair_invoice_no: "@:UI.detail.repairInvoice",
        repair_costs_issued: "Reparaturkosten eingereicht",
        repair_costs_received: "Reparaturkosten reguliert",
        rental_invoice_no: "@:UI.detail.rentalInvoice",
        rental_costs_issued: "Mietwagenkosten eingereicht",
        rental_costs_received: "Mietwagenkosten reguliert",
        substitution_notice_date: "@:UI.detail.substitutionNoticeDate",
        advance_request_date: "@:UI.detail.advanceReceived",
        depreciation_issued: "Wertminderung eingereicht",
        depreciation_received: "Wertminderung reguliert",
        expert_fees_issued: "Gutachterkosten eingereicht",
        expert_fees_received: "Gutachterkosten reguliert",
        placeholder: {
          additional_infos:
            "Bei Nutzung eines Tablets kann hier auch bequem die Spracherfassung Deines Endgeräts verwendet werden.",
        },
      },
      tooltip: {
        IBAN: "Wird benötigt, um Schadenzahlungen an den Kunden vornehmen zu können. Ein Kontozugriff ist nicht möglich.",
        email:
          "Bei Angabe einer Mailadresse kann mit Kund_in schnell und unbürokratisch korrespondiert werden.",
        injuredVatDeduction:
          "Bei Vorsteuerabzugsberechtigung werden die Schadenersatzansprüche netto geltend gemacht. Die anfallenden MwSt.-Beträge sind durch Kund_in direkt bei den Leistungserbringern auszugleichen.",
        roadworthy:
          "Der Zustand gem. Gutachten/Vorabinformation hat u.a. Einfluss auf die Beurteilung, wie lange Anspruch auf Mietwagen/Nutzungsausfall besteht.",
        ownership:
          "Ist ein Fahrzeug geleast/finanziert ist der jeweilige Leasing-/Finanzierungsgeber im Prozess der Schadenabwicklung zu informieren/kontaktieren.",
        national:
          "Passiert im Ausland ein Unfall, so gilt das dortige nationale Recht. Die Schadenersatzansprüche unterscheiden sich entsprechend, mit einer verzögerten Abwicklung ist zu rechnen.",
        policeAlerted:
          "Bei unklarem Schadenhergang kann der polizeiliche Unfallbericht, sofern vorhanden, unter Umständen Klarheit schaffen.",
        accidentCourse:
          "Es empfiehlt sich eine möglichst knappe Originalschilderung von Kund_in.",
        licensePlateCountry:
          "Bei ausländischen Unfallbeteiligten kann sich die Abwicklung des Unfallschadens verzögern.",
        opponentLicensePlate:
          "Mit Hilfe des Kennzeichens kann die gegnerische Versicherung, falls nicht bekannt, identifiziert werden.",
        rentalWanted:
          "Kund_in hat die Möglichkeit zwischen Ersatzmobilität oder einer finanziellen Erstattungsleistung. Die Dauer des Anspruchs unterscheidet sich je nach Abwicklungsart und konkretem Fall",
        repairWanted:
          "Konkret = Das Fahrzeug wird repariert. Fiktiv = Die Entschädigung wird netto gem. Gutachten festgelegt.",
        repairDate:
          "Falls bekannt vermeidet eine Angabe vorzeitige Nachfragen durch Ihren Fallabwickler zum aktuellen Stand.",
        physicalInjury:
          "Personenschäden werden aus Datenschutzgründen separat zum Fahrzeugschaden abgewickelt. Wer betroffen ist, besondere Umstände etc. können in den Zusatzinformationen gepflegt werden.",
        legalInsuranceAvailable:
          "Die Abfrage ist zum Zeitpunkt der Schadenaufnahme rein informativ. Eine Inanspruchnahme einer etwaigen Rechtsschutz wird nur bei Bedarf und im Fallverlauf nach Abstimmung mit dem Kunden getätigt.",
        legalInsuranceId:
          "Wenn Versicherung unbekannt “Andere” auswählen (Ende der Liste)",
        notifyColleague:
          "Es können nur registrierte Nutzer_innen benachrichtigt werden. Eingegebene Mail-Adressen werden nicht berücksichtigt. Ihr Fallabwickler wird in jedem Fall automatisch informiert, sobald eine Nachricht gespeichert wird. Nach Speichern einer Nachricht wird der Verteiler unterhalb des Kommentars angezeigt.",
        sendEmail: "E-Mail an Kund_in senden",
        sendEmailDisabled: "Keine E-Mail für Kund_in hinterlegt",
      },
      dialog: {
        closeCase: {
          title: "Fall abschließen",
          message: "Wollen Sie den Fall wirklich abschließen?",
          confirmText: "@:UI.dialog.closeCase.title",
          cancelText: "@:UI.general.cancel",
        },
        deleteCase: {
          title: "Fall löschen",
          message: "Wollen Sie den Fall wirklich löschen?",
          confirmText: "@:UI.dialog.deleteCase.title",
          cancelText: "@:UI.general.cancel",
        },
        releaseCaseThirdParty: {
          title: "Fall freigeben",
          message:
            "Wenn sie die Freigabe bestätigen wird der Vorgang an Ihren Fallabwickler zur Bearbeitung übermittelt. Wollen Sie das tun?",
          confirmText: "@:UI.dialog.releaseCaseThirdParty.title",
          cancelText: "@:UI.general.cancel",
        },
        releaseCaseOwnFault: {
          title: "@:UI.dialog.releaseCaseThirdParty.title",
          message:
            "Bitte beachten Sie, dass es sinnhaft ist, dass Ihr Kunde den Schaden selbstständig bei seiner Kaskoversicherung meldet/gemeldet hat.<br>" +
            "Weisen Sie ihn darauf hin und fragen Sie auch nach einer etwaigen Werkstattbindung",
          confirmText: "@:UI.dialog.releaseCaseThirdParty.title",
          cancelText: "@:UI.general.cancel",
        },
        locationError: {
          title: "Kein Standort ausgewählt",
          message:
            "Zum Speichern muss ein Standort ausgewählt werden. Dieser kann später bei Bedarf geändert werden.",
          confirmText: "@:UI.general.confirm",
        },
        cancelCaseNoInfos: {
          title: "Fall stornieren",
          message:
            "Damit ein Vorgang storniert werden kann, ist die Eingabe eines Grundes im Feld Zusatzinformationen notwendig.",
          confirmText: "@:UI.general.confirm",
        },
        cancelCase: {
          title: "@:UI.dialog.cancelCaseNoInfos.title",
          message: "Wollen Sie den Fall wirklich stornieren?",
          confirmText: "@:UI.dialog.cancelCase.title",
          cancelText: "@:UI.general.cancel",
        },
        addFilter: {
          title: "Filter hinzufügen",
          cancelText: "@:UI.dialog.closeCase.cancelText",
          confirmText: "@:UI.dialog.addFilter.title",
        },
        addPOAForm: {
          title: "Unvollständige Daten",
          injuredNameMissing: "Kein Name für Kund_in verfügbar",
          accidentDateMissing: "Kein Unfalldatum angegeben",
          accidentPlaceMissing: "Kein Unfallort angegeben",
          questionText:
            "<br>Die Vollmacht kann nur erstellt werden, wenn die oben genannten Informationen gespeichert sind. Sobald die Datenfelder befüllt sind kann die Vollmacht erstellt und digital unterschrieben werden.",
          confirmText: "@:UI.general.confirm",
        },
        deleteAttachment: {
          title: "Anhang löschen",
          message:
            "Soll der Anhang wirklich gelöscht werden?<br><br>Eine Wiederherstellung ist dann nicht mehr möglich!",
          confirmText: "@:UI.dialog.deleteAttachment.title",
          cancelText: "Abbrechen",
        },
        sendPoaToCustomer: {
          title: "Vollmacht senden",
          message: "Soll die Vollmacht an den Kunden gesendet werden?",
          confirmText: "@:UI.enum.general.YES",
          cancelText: "@:UI.enum.general.NO",
        },
        sendDocumentToCustomer: {
          title: "Dokument senden",
          message: "Soll das Dokument an den Kunden gesendet werden?",
          confirmText: "@:UI.enum.general.YES",
          cancelText: "@:UI.enum.general.NO",
        },
      },
      error: {
        general: {
          title: "Fehler",
          notAllowed: "Der Zugriff auf diese Seite wurde verweigert",
          locationLoading: "Kunden-Liste konnte nicht geladen werden",
          missingLocation: "Bitte Standort auswählen",
        },
        server_na: {
          message: "Server wurde nicht erreicht.<br><br>Sie werden ausgeloggt.",
          confim: "Ausloggen",
        },
        overview: {
          loadingCases: "Fehler beim Laden der Übersicht",
        },
        cases: {
          loading: "Fehler beim Laden des Falls",
          create: "Fehler beim Speichern des neuen Falls",
          update: {
            default: "Fehler beim Speichern des Falls",
            case_modified:
              "Fehler beim Speichern des Falls. Der Fall wurde zwischenzeitlich von einem anderen Nutzer geändert. Bitte laden Sie die Seite neu.",
          },
          notAllowed: "Der Zugriff auf diesen Fall wurde verweigert",
        },
        comments: {
          loading: "Fehler beim Laden der Kommentare",
          create: "Fehler beim Speichern des Kommentars",
        },
        history: {
          loading: "Fehler beim Laden der Fallhistorie",
        },
        attachment: {
          upload: "Fehler beim Hochladen der Datei",
          download: "Fehler beim Herunterladen der Datei",
          delete: "Fehler beim Löschen der Datei",
          load: "Fehler beim Laden der Anhänge",
          createPoa: "Fehler beim Erstellen der Vollmacht",
          sendToJL: "Fehler beim Übertragen des Falls nach J-Lawyer",
          updateTag: "Fehler beim Setzen des Tags",
        },
        status: {
          update: "Fehler beim Aktualisieren des Status",
        },
        data: {
          insurance: "Fehler beim Laden der Versicherungsliste",
          legalInsurance:
            "Fehler beim Laden der Verkehrsrechtsschutzversicherungen",
          location: "Fehler beim Laden der Standortliste",
          partner: "Fehler beim Laden der Partnerliste",
          evaluation: "Fehler beim Laden der Auswertung",
          colleagues: "Fehler beim Laden der Liste aller Kollegen",
          caseHandler: "Fehler beim Laden der Fallabwickler Liste",
          appraisersUsers: "Fehler beim Laden der Gutachter Benutzer Liste",
          appraisers: "Fehler beim Laden der Gutachter Liste",
        },
        admin: {
          loadCaseHandler: "Fehler beim laden der Fallabwickler",
          addInsuranceLoading: "Fehler hinzufügen der fehlenden Versicherungen",
        },
        iban: {
          0: "Keine IBAN angegeben", // NoIBANProvided
          1: "Kein Land für die IBAN angegeben", // NoIBANCountry
          2: "Falsche Länge der IBAN", // WrongBBANLength
          3: "Falsches Format der IBAN", // WrongBBANFormat
          4: "Prüfsumme ist keine Zahl", // ChecksumNotNumber
          5: "Falsche Prüfsumme der IBAN", // WrongIBANChecksum
          6: "Falsche Prüfsumme der IBAN", // WrongAccountBankBranchChecksum
          7: "QR-IBAN nicht erlaubt", // QRIBANNotAllowed
        },
      },
      message: {
        noResultsFor: "Keine Ergebnisse für",
        selectCountry: "Land wählen",
        searchInsurance: "Versicherung wählen",
        notAvailable: "-",
        success: "Erfolgreich",
        createPoa: "Die Vollmacht wird erstellt",
        addedPoa: "Die Vollmacht wurde erfolgreich erstellt",
        sendToJL: "Der Fall wird an J-Lawyer übergeben",
        sendedToJL: "Der Fall wurde erfolgreich an J-Lawyer übergeben",
        attachmentUpdated:
          "Datei {fileName} erfolgreich gespeichert. @:UI.message.caseHandlerNotification",
        caseHandlerNotification:
          "Ihr Fallabwickler wurde automatisch informiert.",
        caseHandlerNotNotified:
          "Achtung: bei Vorgängen im Entwurfsstatus wird Ihr Fallabwickler nicht informiert.",
        attachmentAdded:
          "Datei {fileName} erfolgreich hinzugefügt. @:UI.message.caseHandlerNotification",
        attachmentDeleted: "Datei {fileName} erfolgreich entfernt.",
        commentAdded: "Kommentar erfolgreich gespeichert.",
        commentAddedReleased:
          " @:UI.message.commentAdded @:UI.message.caseHandlerNotification",
        commentAddedUnreleased:
          " @:UI.message.commentAdded @:UI.message.caseHandlerNotNotified",
        documentSent: "Dokument an Kund_in gesendet",
      },
      history: {
        from: ", von {name}",
        field: "Feld",
        oldValue: "Alter Wert",
        newValue: "Neuer Wert",
        empty: "(undefiniert)",
        viewMore: "Mehr anzeigen",
        viewLess: "Weniger anzeigen",
        loading: "Historie wird geladen",
        attachment_added_tag: "'{tag}' zu Datei {file_name}",
        attachment_modified_tag:
          "'{new_tag}' von '{old_tag}' bei Datei {file_name}",
        attachment_deleted_tag: "'{tag}' von {file_name}",
      },
      enum: {
        general: {
          SELECT: "Bitte wählen",
          YES: "Ja",
          NO: "Nein",
          UNKNOWN: "Unbekannt/Unklar",
          OTHER: "Andere",
        },
        status: {
          NONE: "@:UI.enum.general.SELECT",
          WORK_IN_PROGRESS: "Entwurf",
          RELEASED: "In Bearbeitung",
          CLOSED: "Abgeschlossen",
          CANCELLED: "Storniert",
        },
        tag: {
          LIABILITY_CONFIRMATION_ISSUED: "Haftungsbestätigung erteilt",
          RENTAL_CAR_PRICE_INFORMATION_RECEIVED:
            "Mietwagen: Preisvorabinformation",
          ADVANCE_RECEIVED: "Vorschuss erhalten",
        },
        reporter: {
          NONE: "@:UI.enum.general.SELECT",
          DRIVER: "Fahrer_in",
          OWNER: "Halter_in",
        },
        vehicle_ownership: {
          NONE: "@:UI.enum.general.SELECT",
          OWN_PROPERTY: "...in Eigentum",
          FINANCED: "...finanziert",
          LEASED: "...geleast",
          UNKNOWN: "Unbekannt",
        },
        kind: {
          NONE: "@:UI.enum.general.SELECT",
          PRIVATE: "Privat",
          COMPANY: "Firma",
        },
        decision: {
          NONE: "@:UI.enum.general.SELECT",
          YES: "@:UI.enum.general.YES",
          NO: "@:UI.enum.general.NO",
          UNKNOWN: "@:UI.enum.general.UNKNOWN",
        },
        advisory: {
          NONE: "@:UI.enum.general.SELECT",
          YES: "@:UI.enum.general.YES",
          NO: "@:UI.enum.general.NO",
          SHORT_ADVISORY: "Kurzgutachten/Schadenkalkulation",
          COST_ESTIMATE: "Kostenvoranschlag Autohaus",
        },
        title: {
          NONE: "@:UI.enum.general.SELECT",
          MR: "Herr",
          MRS: "Frau",
          COMPANY: "Firma",
          OTHER: "Divers/Andere",
        },
        rental_wanted: {
          NONE: "@:UI.enum.general.SELECT",
          RENTAL: "Mietwagen",
          OUTAGE_COSTS: "Nutzungsausfall",
          UNKNOWN: "@:UI.enum.general.UNKNOWN",
        },
        repair_wanted: {
          NONE: "@:UI.enum.general.SELECT",
          CONCRETE: "Konkret",
          FICTIONAL: "Fiktiv",
          TOTAL_DAMAGE: "Totalschaden",
          UNKNOWN: "@:UI.enum.general.UNKNOWN",
        },
        roadworthy: {
          NONE: "@:UI.enum.general.SELECT",
          FULL: "Fahrbereit und verkehrssicher",
          NOT_ALLOWED: "Fahrbereit und nicht verkehrssicher",
          NO: "Nicht fahrbereit und nicht verkehrssicher",
          UNKNOWN: "@:UI.enum.general.UNKNOWN",
        },
        damage_type: {
          NONE: "@:UI.enum.general.SELECT",
          THIRD_PARTY_LIABILITY: "Haftpflicht",
          OWN_FAULT: "Teil-/Vollkasko",
        },
        filterField: {
          NONE: "@:UI.enum.general.SELECT",
          STATUS: "Status",
          CREATED: "Erstellungsdatum",
          UPDATED: "Zuletzt Geändert",
          CLOSED: "Abschlussdatum",
          LOCATION: "Standort",
          CUSTOMER_NAME: "Name Kund_in",
          LICENSE_PLATE: "Kennzeichen",
          FILE_SIGN: "@:UI.form.file_sign",
          REPAIR_WANTED: "@:UI.form.repair_wanted",
          CASE_HANDLER: "@:UI.form.caseHandler",
          DAMAGE_TYPE: "@:UI.form.damage_type",
          EXPERT_REFERENCE: "Gutachter Referenz",
        },
        caseFilters: {
          REPAIR_DATE_MISSING: "Fehlendes Reparaturdatum",
        },
        sortField: {
          LAST_MODIFICATION_DATE: "Zuletzt Geändert",
          CREATION_DATE: "Erstelldatum",
          RELEASE_DATE: "Freigabedatum",
        },
        sortDirection: {
          ASC: "Aufsteigend",
          DESC: "Absteigend",
        },
        diffType: {
          OTHER: "",
          CASE: "Akte",
          INJURED_PARTY_ADDRESS: "@:UI.form.injuredParty",
          OPPONENT_OWNER_ADDRESS: "@:UI.form.owner",
          OPPONENT_DRIVER_ADDRESS: "@:UI.form.driver",
          WITNESS_ADDRESS: "@:UI.form.witness",
          ATTACHMENT: "Anhang",
          ATTACHMENT_TAG: "Anhang Tag",
          COMMENT: "Kommentar",
          EVENT: "Ereignis",
          EVALUATION_VALUES: "@:UI.detail.evaluationDetails",
        },
        diffReason: {
          OTHER: "",
          ADDED: "hinzugefügt",
          MODIFIED: "geändert",
          DELETED: "gelöscht",
        },
        diffEvent: {
          TAG: "",
          STATUS: "Fall Status wurde auf",
          MAIL_TO_CUSTOMER: "Dokument an Kund_in gesendet",
        },
        user_active: {
          ACTIVE: "Aktiv",
          INACTIVE: "Inaktiv",
          DISABLED: "Deaktiviert",
        },
        attachment_tag: {
          NONE: "Keiner",
          POA: "Vollmacht blanko",
          POA_SIGNED: "Vollmacht unterschrieben",
          INVOICE_REPAIR: "Reparaturrechnung",
          ADVISORY: "Gutachten",
          INVOICE_RENTAL: "Mietwagenrechnung",
          INVOICE_ADVISORY: "Gutachtenrechnung",
          PICTURE: "Bild",
          LETTER_INSURANCE: "Versicherungsschreiben",
          LETTER_POLICE: "Polizei",
          OTHER: "Sonstiges",
          QUANTIFICATION: "Bezifferung",
          TRANSPORTATION_DOCUMENTS: "Verbringungsnachweise",
          EXPERT_ASSISTANCE_COSTS: "Gutachterhilfskosten",
          EXPERT_OPINION_ORDER: "Gutachtenauftrag",
        },
        regulation_types: {
          REPAIR_COSTS: "Reparaturkosten",
          RENTAL_CAR_COSTS: "Mietwagenkosten",
          DEPRECIATION: "Wertminderung",
          EXPERT_FEES: "Gutachterkosten",
        },
        country: {
          NONE: "@:UI.enum.general.SELECT",
          UA: "Ukraine",
          FR: "Frankreich",
          ES: "Spanien",
          SE: "Schweden",
          DE: "Deutschland",
          FI: "Finnland",
          NO: "Norwegen",
          PL: "Polen",
          IT: "Italien",
          GB: "Großbritannien",
          RO: "Rumänien",
          BY: "Belarus",
          GR: "Griechenland",
          BG: "Bulgarien",
          IS: "Island",
          PT: "Portugal",
          CZ: "Tschechien",
          DK: "Dänemark",
          HU: "Ungarn",
          RS: "Serbien",
          AT: "Österreich",
          IE: "Irland",
          LT: "Litauen",
          LV: "Lettland",
          HR: "Kroatien",
          BA: "Bosnien und Herzegowina",
          SK: "Slowakei",
          EE: "Estland",
          NL: "Niederlande",
          CH: "Schweiz",
          MD: "Moldawien",
          BE: "Belgien",
          AL: "Albanien",
          MK: "Mazedonien",
          SI: "Slowenien",
          ME: "Montenegro",
          CY: "Zypern",
          LU: "Luxemburg",
          FO: "Färöer",
          AD: "Andorra",
          MT: "Malta",
          LI: "Liechtenstein",
          GG: "Guernsey",
          SM: "San Marino",
          GI: "Gibraltar",
          MC: "Monaco",
          VA: "Vaticanstadt",
        },
      },
    },
  },
};

export default messages;
